<template>
  <div class="container">
    <pre style="display: none">{{ pagination | pretty_json }}}</pre>
    <h1>Lista de Municípios</h1>
    <div class="card">
      <div class="card-body">
        <b-table striped hover :items="counties" :fields="fields">
          <template #cell(actions)="row">
            <Actions
              :item="row.item"
              :view_show="false"
              :view_edit="$can('county:edit')"
              :view_delete="$can('county:delete')"
              edit_to="counties_edit"
              @delete="delete_county"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="overflow-auto mt-4">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
      ></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import Actions from "@/components/Table/Actions.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Actions },
  data: () => {
    return {
      page: 1,
    };
  },
  computed: {
    ...mapState("counties", ["counties", "pagination"]),
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "name", label: "Município" },
        { key: "state_initials", label: "UF" },
        { key: "capital", label: "Capital" },
        { key: "actions", label: "#", class: "col-1" },
      ];
    },
  },
  methods: {
    ...mapActions("counties", ["ActionGetAll"]),
    delete_county(county) {
      return county;
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
  },
  async created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }
    await this.ActionGetAll({ page: this.page });
  },
};
</script>

<style>
</style>
